const paths = {
  author: '/:authorName/author/:authorId([0-9a-f]{8})',
  book: '/:bookTitle/book/:bookId([0-9]{13})',
  bookAbbr: '/book/:bookId([0-9]{13})',
  bookmarks: '/user/:userId/bookmarks',
  calendar: '/calendar',
  calendarByCategory:
    '/calendar/:categoryName/:categoryId(boys|girls|youth|womens|others)',
  comic: '/:comicTitle/comic/:comicId([0-9a-f]{8})',
  comicAbbr: '/comic/:comicId([0-9a-f]{8})',
  genre: '/:genreName/genre/:genreCode',
  hot: '/hot',
  hotByCategory:
    '/:categoryName/hot/:categoryId(boys|girls|youth|womens|others)',
  home: '/',
  label: '/:labelName/label/:labelId',
  labelAbbr: '/label/:labelId',
  magazine: '/:magazineName/magazine/:magazineId',
  notifications: '/notifications',
  search: '/search',
  signin: '/signin',
  subgenre: '/:genreName/:subGenreName/genre/:genreCode/:subgenreCode',
  user: '/user/:userId',
};

export default paths;
