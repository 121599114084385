import firebase from 'firebase/app';

import { User } from '../models/user';
import { collectionName } from '../constants';

const findUser = async (db: firebase.firestore.Firestore, id: string) => {
  const doc = await db
    .collection(collectionName.users)
    .doc(id)
    .get();

  return doc.exists ? ({ ...doc.data(), id: doc.id } as User) : null;
};

export default findUser;
