import React, { FC, lazy, Suspense, useContext } from 'react';
import { Redirect, Route, Switch } from 'react-router';

import { UserContext } from 'contexts';
import paths from './paths';

const Book = lazy(() => import('components/pages/Book'));
const Calendar = lazy(() => import('containers/pages/Calendar'));
const Comic = lazy(() => import('components/pages/Comic'));
const Home = lazy(() => import('components/pages/Home'));
const Hot = lazy(() => import('components/pages/Hot'));
const LabelPage = lazy(() => import('containers/pages/Label'));
const Search = lazy(() => import('components/pages/Search'));
const Signin = lazy(() => import('components/pages/Signin'));
const Spacer = lazy(() => import('components/atoms/Spacer'));
const NavigationBar = lazy(() =>
  import('components/molecules/menu/NavigationBar'),
);

const App: FC = () => {
  const { user } = useContext(UserContext);

  return (
    <div>
      <Suspense fallback={<div />}>
        <NavigationBar />
        <Spacer height={3.5} />
        <Switch>
          <Route path={paths.book} component={Book} />
          <Route path={paths.bookAbbr} component={Book} />
          <Route path={paths.calendar} component={Calendar} exact />
          <Route path={paths.comic} component={Comic} />
          <Route path={paths.comicAbbr} component={Comic} />
          <Route path={paths.hotByCategory} component={Hot} />
          <Route path={paths.hot} component={Hot} exact />
          <Route path={paths.label} component={LabelPage} exact />
          <Route path={paths.labelAbbr} component={LabelPage} />
          <Route path={paths.search} component={Search} />
          <Route path={paths.home} component={Home} exact />
          {!user && <Route path={paths.signin} component={Signin} />}
          <Redirect to={paths.home} />
        </Switch>
      </Suspense>
    </div>
  );
};

export default App;
