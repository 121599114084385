export const BASE_URL = 'https://mangarel.com';

export const collectionName = {
  authors: 'authors',
  books: 'books',
  bookmarkedComics: 'bookmarkedComics',
  comics: 'comics',
  labels: 'labels',
  magazines: 'magazines',
  users: 'users',
  publishers: 'publishers',
  notifications: 'notifications',
  ngLabelWords: 'ngLabelWords',
  ngPublisherWords: 'ngPublisherWords',
  recordCounters: 'recordCounters',
  feedMemos: 'feedMemos',
} as const;

export const bookTypeMap = {
  normal: '通常版',
  special: '特装版',
  fanbook: 'ファンブック',
  anthology: 'アンソロジー',
};
export type BookTypeCode = keyof typeof bookTypeMap;

export const frequencyMap = {
  weekly: '週刊',
  biweekly: '隔週刊',
  monthly: '月刊',
  bimonthly: '隔月刊',
  quarterly: '季刊',
  irregularly: '週刊',
};
export type FrequencyCode = keyof typeof frequencyMap;

export const mediaMap = {
  paper: '紙雑誌',
  digital: '電子雑誌',
  online: 'オンライン',
};
export type MediaCode = keyof typeof mediaMap;

export const deleteReason = {
  byAdmin: '管理者による操作',
  byUser: 'ユーザーによる操作',
  withConclusion: 'シリーズ完結',
};
export type DeleteReasonCode = keyof typeof deleteReason;

export const roleMap = {
  arrangedBy: '構成',
  artBy: '作画',
  author: '漫画',
  characterDesign: 'キャラデザ',
  consultedBy: '監修',
  editedBy: '編集',
  other: 'その他',
  plannedBy: '原案',
  storyBy: '原作',
};
export type RoleCode = keyof typeof roleMap;

export const categoryMap = {
  kids: '子供向け',
  boys: '少年漫画',
  girls: '少女漫画',
  youth: '青年漫画',
  womens: '女性漫画',
  ladies: 'レディース',
  adult: 'アダルト',
  bl: 'BL',
  others: 'その他',
  mixed: '混合',
};
export type CategoryCode = keyof typeof categoryMap;
export const categoryKeys = Object.keys(categoryMap);

// Genres
export const genreMap: { [k: string]: { name: string; icon: string } } = {
  battle: { name: 'バトル', icon: 'hotjar' },
  adventure: { name: '冒険、アクション', icon: 'compass outline' },
  history: { name: '歴史、時代', icon: 'globe' },
  military: { name: 'ミリタリー', icon: 'bomb' },
  sf: { name: 'SF', icon: 'rocket' },
  supernatural: { name: '伝奇', icon: 'certificate' },
  suspense: { name: 'サスペンス', icon: 'eye' },
  underground: { name: '裏社会', icon: 'spy' },
  game: { name: 'ゲーム', icon: 'chess knight' },
  sports: { name: 'スポーツ', icon: 'futbol' },
  martiaArts: { name: '格闘技', icon: 'hand rock outline' },
  vehicle: { name: '乗り物', icon: 'car' },
  outdoor: { name: 'アウトドア', icon: 'map signs' },
  performance: { name: '舞台芸術', icon: 'video' },
  art: { name: '音楽、美術', icon: 'music' },
  school: { name: '学園', icon: 'student' },
  profession: { name: '職業', icon: 'briefcase' },
  medicine: { name: '医療', icon: 'stethoscope' },
  food: { name: '食', icon: 'food' },
  love: { name: '恋愛', icon: 'heart outline' },
  yuri: { name: '百合', icon: 'venus double' },
  sexualMinority: { name: 'セクマイ', icon: 'gay' },
  everyday: { name: '日常、ギャグ', icon: 'comments outline' },
  human: { name: '人生', icon: 'users' },
  animal: { name: '動物', icon: 'paw' },
  essay: { name: 'エッセイ', icon: 'leaf' },
  others: { name: 'その他', icon: 'block layout' },
};

export type GenreCode = keyof typeof genreMap;

export const subgenreMap: {
  [k: string]: { genreCode: GenreCode; name: string; icon: string };
} = {
  superpower: { genreCode: 'battle', name: '異能バトル', icon: 'hotjar' },
  darkFantasy: {
    genreCode: 'battle',
    name: 'ダークファンタジー',
    icon: 'hotjar',
  },
  hero: { genreCode: 'battle', name: 'ヒーロー', icon: 'hotjar' },
  periodDrama: { genreCode: 'battle', name: '時代劇', icon: 'hotjar' },
  magicalGirl: { genreCode: 'battle', name: '魔法少女', icon: 'magic' },
  swordMagic: {
    genreCode: 'adventure',
    name: '剣と魔法',
    icon: 'compass outline',
  },
  historicalAdventure: {
    genreCode: 'adventure',
    name: '歴史冒険',
    icon: 'compass outline',
  },
  expedition: {
    genreCode: 'adventure',
    name: '異世界探検',
    icon: 'compass outline',
  },
  apocalypse: {
    genreCode: 'adventure',
    name: '終末世界',
    icon: 'compass outline',
  },
  survival: {
    genreCode: 'adventure',
    name: 'サバイバル',
    icon: 'compass outline',
  },
  crimeAction: {
    genreCode: 'adventure',
    name: 'クライムアクション',
    icon: 'compass outline',
  },
  heian: { genreCode: 'history', name: '奈良・平安時代', icon: 'globe' },
  sengoku: { genreCode: 'history', name: '戦国時代', icon: 'globe' },
  edo: { genreCode: 'history', name: '江戸時代', icon: 'globe' },
  modernJapan: { genreCode: 'history', name: '近代日本', icon: 'globe' },
  ancientChina: { genreCode: 'history', name: '古代中国', icon: 'globe' },
  ancientEurope: {
    genreCode: 'history',
    name: '古代ヨーロッパ',
    icon: 'globe',
  },
  medievalEurope: {
    genreCode: 'history',
    name: '中世ヨーロッパ',
    icon: 'globe',
  },
  modernWestern: { genreCode: 'history', name: '近代西洋', icon: 'globe' },
  middleEast: { genreCode: 'history', name: '中東', icon: 'globe' },
  historicalFantasy: {
    genreCode: 'history',
    name: '歴史ファンタジー',
    icon: 'globe',
  },
  modernWar: { genreCode: 'military', name: '近代戦', icon: 'bomb' },
  contemporaryWar: { genreCode: 'military', name: '現代戦', icon: 'bomb' },
  army: { genreCode: 'military', name: '軍隊', icon: 'bomb' },
  militaryFantasy: {
    genreCode: 'military',
    name: '軍事ファンタジー',
    icon: 'bomb',
  },
  spaceDevelopment: { genreCode: 'sf', name: '宇宙開発', icon: 'rocket' },
  sfFantasy: { genreCode: 'sf', name: 'SFファンタジー', icon: 'rocket' },
  sfAction: { genreCode: 'sf', name: 'SFアクション', icon: 'rocket' },
  monster: { genreCode: 'sf', name: 'モンスター、エイリアン', icon: 'rocket' },
  robot: { genreCode: 'sf', name: 'ロボット', icon: 'rocket' },
  ai: { genreCode: 'sf', name: 'AI、アンドロイド', icon: 'rocket' },
  cyberPunk: { genreCode: 'sf', name: 'サイバーパンク', icon: 'rocket' },
  steamPunk: { genreCode: 'sf', name: 'スチームパンク', icon: 'rocket' },
  future: { genreCode: 'sf', name: '近未来', icon: 'rocket' },
  specter: {
    genreCode: 'supernatural',
    name: '妖怪、魔族',
    icon: 'certificate',
  },
  ghost: { genreCode: 'supernatural', name: '幽霊', icon: 'certificate' },
  wizard: { genreCode: 'supernatural', name: '魔法使い', icon: 'certificate' },
  horror: { genreCode: 'supernatural', name: 'ホラー', icon: 'certificate' },
  occult: { genreCode: 'supernatural', name: 'オカルト', icon: 'certificate' },
  mystery: { genreCode: 'suspense', name: 'ミステリー', icon: 'eye' },
  thriller: { genreCode: 'suspense', name: 'スリラー', icon: 'eye' },
  gang: { genreCode: 'underground', name: 'ギャング、ヤクザ', icon: 'spy' },
  badkids: { genreCode: 'underground', name: '不良少年', icon: 'spy' },
  hardboiled: { genreCode: 'underground', name: 'ハードボイルド', icon: 'spy' },
  blackBusiness: { genreCode: 'underground', name: '闇稼業', icon: 'spy' },
  gamble: { genreCode: 'game', name: 'ギャンブル', icon: 'chess knight' },
  mahjong: { genreCode: 'game', name: '麻雀', icon: 'chess knight' },
  shogi: { genreCode: 'game', name: '将棋', icon: 'chess knight' },
  tableGame: {
    genreCode: 'game',
    name: 'テーブルゲーム',
    icon: 'chess knight',
  },
  computerGame: {
    genreCode: 'game',
    name: 'コンピュータゲーム',
    icon: 'gamepad',
  },
  culturalGame: { genreCode: 'game', name: '文化系競技', icon: 'chess knight' },
  baseball: { genreCode: 'sports', name: '野球', icon: 'baseball ball' },
  soccer: { genreCode: 'sports', name: 'サッカー', icon: 'futbol' },
  volleyball: {
    genreCode: 'sports',
    name: 'バレーボール',
    icon: 'volleyball ball',
  },
  basketball: {
    genreCode: 'sports',
    name: 'バスケットボール',
    icon: 'basketball ball',
  },
  tennis: { genreCode: 'sports', name: 'テニス', icon: 'table tennis' },
  pingPong: { genreCode: 'sports', name: '卓球', icon: 'table tennis' },
  badminton: {
    genreCode: 'sports',
    name: 'バドミントン',
    icon: 'table tennis',
  },
  athletics: { genreCode: 'sports', name: '陸上', icon: 'stopwatch' },
  golf: { genreCode: 'sports', name: 'ゴルフ', icon: 'golf ball' },
  cycleRace: { genreCode: 'sports', name: '自転車レース', icon: 'bicycle' },
  winterSports: {
    genreCode: 'sports',
    name: 'ウィンタースポーツ',
    icon: 'snowflake',
  },
  minorSports: {
    genreCode: 'sports',
    name: 'マイナースポーツ',
    icon: 'futbol',
  },
  boxing: {
    genreCode: 'martialArts',
    name: 'ボクシング',
    icon: 'hand rock outline',
  },
  strinkeArts: {
    genreCode: 'martialArts',
    name: '拳法、空手',
    icon: 'hand rock outline',
  },
  mma: {
    genreCode: 'martialArts',
    name: '総合格闘技',
    icon: 'hand rock outline',
  },
  sword: { genreCode: 'martialArts', name: '剣', icon: 'hand rock outline' },
  judo: { genreCode: 'martialArts', name: '柔道', icon: 'hand rock outline' },
  sumo: { genreCode: 'martialArts', name: '相撲', icon: 'hand rock outline' },
  car: { genreCode: 'vehicle', name: '自動車', icon: 'car' },
  motorbike: { genreCode: 'vehicle', name: 'バイク', icon: 'motorcycle' },
  bicycle: { genreCode: 'vehicle', name: '自転車', icon: 'bicycle' },
  climbing: { genreCode: 'outdoor', name: '登山', icon: 'map signs' },
  camp: { genreCode: 'outdoor', name: 'キャンプ', icon: 'map signs' },
  fishing: { genreCode: 'outdoor', name: '釣り', icon: 'map signs' },
  diving: { genreCode: 'outdoor', name: 'ダイビング', icon: 'map signs' },
  drama: { genreCode: 'performing', name: '演劇', icon: 'video' },
  musical: {
    genreCode: 'performing',
    name: 'ミュージカル、オペラ',
    icon: 'video',
  },
  ballet: { genreCode: 'performing', name: 'バレエ', icon: 'video' },
  dance: { genreCode: 'performing', name: 'ダンス', icon: 'video' },
  traditionalArt: { genreCode: 'performing', name: '伝統芸能', icon: 'video' },
  owarai: { genreCode: 'performing', name: 'お笑い', icon: 'video' },
  classic: { genreCode: 'art', name: 'クラシック音楽', icon: 'music' },
  jazz: { genreCode: 'art', name: 'ジャズ', icon: 'music' },
  rock: { genreCode: 'art', name: 'ロック、ポップス', icon: 'music' },
  rnb: { genreCode: 'art', name: 'R&B、ヒップホップ', icon: 'music' },
  fineArt: { genreCode: 'art', name: '美術', icon: 'paint brush' },
  subCulture: { genreCode: 'art', name: 'サブカルチャー', icon: 'film' },
  schoolLife: { genreCode: 'school', name: '学校生活', icon: 'student' },
  specialCourse: { genreCode: 'school', name: '専科', icon: 'student' },
  preparatory: { genreCode: 'school', name: '受験', icon: 'student' },
  teacher: { genreCode: 'school', name: '教師', icon: 'student' },
  studentCouncil: { genreCode: 'school', name: '生徒会', icon: 'student' },
  schoolIncident: { genreCode: 'school', name: '校内事件', icon: 'student' },
  schoolFantasy: {
    genreCode: 'school',
    name: '学園ファンタジー',
    icon: 'student',
  },
  manga: { genreCode: 'profession', name: '漫画', icon: 'images outline' },
  production: { genreCode: 'profession', name: '制作', icon: 'film' },
  showbiz: { genreCode: 'profession', name: '芸能界', icon: 'star outline' },
  media: { genreCode: 'profession', name: 'マスコミ', icon: 'camera' },
  fashion: { genreCode: 'profession', name: '服飾', icon: 'shopping bag' },
  money: {
    genreCode: 'profession',
    name: 'マネー',
    icon: 'money bill alternate outline',
  },
  businessPerson: {
    genreCode: 'profession',
    name: 'ビジネスパーソン',
    icon: 'briefcase',
  },
  workingWomen: {
    genreCode: 'profession',
    name: 'ワーキングウーマン',
    icon: 'briefcase',
  },
  police: { genreCode: 'profession', name: '警察', icon: 'spy' },
  publicServant: { genreCode: 'profession', name: '公務員', icon: 'briefcase' },
  hacker: {
    genreCode: 'profession',
    name: 'ハッカー',
    icon: 'keyboard outline',
  },
  agriculture: { genreCode: 'profession', name: '農業', icon: 'lemon' },
  hunter: { genreCode: 'profession', name: '猟師', icon: 'gavel' },
  nightclub: { genreCode: 'profession', name: '水商売', icon: 'glass martini' },
  doctor: { genreCode: 'medicine', name: '医師', icon: 'stethoscope' },
  nurse: { genreCode: 'medicine', name: '看護師', icon: 'stethoscope' },
  pharmacist: { genreCode: 'medicine', name: '薬剤師', icon: 'pills' },
  medicalTechnician: {
    genreCode: 'medicine',
    name: '医療技師',
    icon: 'stethoscope',
  },
  chefBattle: { genreCode: 'food', name: '料理人バトル', icon: 'food' },
  eatingTour: { genreCode: 'food', name: '食べ歩き', icon: 'food' },
  cooking: { genreCode: 'food', name: '家庭料理', icon: 'food' },
  restaurant: { genreCode: 'food', name: 'レストラン', icon: 'food' },
  liquor: { genreCode: 'food', name: '酒', icon: 'beer' },
  loveComedy: { genreCode: 'love', name: 'ラブコメ', icon: 'heart' },
  boyMeetsGirl: {
    genreCode: 'love',
    name: 'ボーイミーツガール',
    icon: 'heart',
  },
  couple: { genreCode: 'love', name: 'カップル', icon: 'heart' },
  mayDecember: { genreCode: 'love', name: '年の差', icon: 'heart' },
  affair: { genreCode: 'love', name: '不倫', icon: 'heart' },
  loveWandering: { genreCode: 'love', name: '恋愛遍歴', icon: 'heart' },
  senpaiYuri: {
    genreCode: 'yuri',
    name: '先輩×後輩百合',
    icon: 'venus double',
  },
  friendYuri: { genreCode: 'yuri', name: '友達百合', icon: 'venus double' },
  sisterYuri: { genreCode: 'yuri', name: '姉妹百合', icon: 'venus double' },
  adultYuri: { genreCode: 'yuri', name: '社会人百合', icon: 'venus double' },
  teacherYuri: {
    genreCode: 'yuri',
    name: '教師×生徒百合',
    icon: 'venus double',
  },
  oneloli: { genreCode: 'yuri', name: 'おねロリ', icon: 'venus double' },
  gay: { genreCode: 'sexualMinority', name: 'ゲイ', icon: 'gay' },
  lesbian: {
    genreCode: 'sexualMinority',
    name: 'レズビアン',
    icon: 'venus double',
  },
  transgender: {
    genreCode: 'sexualMinority',
    name: 'トランスジェンダー',
    icon: 'transgender',
  },
  tomgirl: { genreCode: 'sexualMinority', name: '男の娘', icon: 'transgender' },
  nonbinary: {
    genreCode: 'sexualMinority',
    name: '性別不明',
    icon: 'transgender alternate',
  },
  dsd: {
    genreCode: 'sexualMinority',
    name: 'DSD（性分化疾患）',
    icon: 'transgender alternate',
  },
  sliceOfLife: { genreCode: 'everyday', name: '空気系', icon: 'comments' },
  solFantasy: {
    genreCode: 'everyday',
    name: '日常系ファンタジー',
    icon: 'comments',
  },
  orthodoxGag: { genreCode: 'everyday', name: '王道ギャグ', icon: 'comments' },
  girlsGag: { genreCode: 'everyday', name: 'ガールズギャグ', icon: 'comments' },
  surrealGag: {
    genreCode: 'everyday',
    name: 'シュールギャグ',
    icon: 'comments',
  },
  domestic: { genreCode: 'human', name: 'ホームドラマ', icon: 'users' },
  religion: { genreCode: 'human', name: '宗教', icon: 'users' },
  cat: { genreCode: 'animal', name: '猫', icon: 'paw' },
  veterinarian: { genreCode: 'animal', name: '獣医', icon: 'paw' },
  animaWelfare: { genreCode: 'animal', name: '動物愛護', icon: 'paw' },
  parenting: { genreCode: 'essay', name: '育児', icon: 'leaf' },
  marriageHunting: { genreCode: 'essay', name: '婚活', icon: 'leaf' },
  autobiography: { genreCode: 'essay', name: '自伝', icon: 'leaf' },
};

export type SubgenreCode = keyof typeof subgenreMap;

export type DescriptionOption = {
  maxLength?: number;
  includesTitle?: boolean;
  includesLabel?: boolean;
};

export const defaultDescriptionOption: DescriptionOption = {
  includesTitle: false,
  includesLabel: false,
};
